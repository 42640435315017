import { Client } from "@microsoft/microsoft-graph-client";
import { graphConfig, loginRequest } from "./authConfig";

const API_URL = process.env.REACT_APP_API;

export const getAPI = async (endpoint: string, account: any, instance: any) => {

    if (account) {
        return instance.acquireTokenSilent({
            ...loginRequest,
            account: account
        }).then((response: { accessToken: string; }) => {
            var headers = new Headers();
            var bearer = "Bearer " + response.accessToken;
            headers.append("X-Auth", bearer);
            var options = {
                method: "GET",
                headers: headers
            };
            var url = API_URL + endpoint;
            return fetch(url, options)
                .then(response => response.json())
                .then(response => { return response; });
        });
    }
}

export const postAPI = async (endpoint: string, data: any, account: any, instance: any) => {
    var tempData = JSON.stringify(data)

    if (account) {
        return instance.acquireTokenSilent({
            ...loginRequest,
            account: account
        }).then((response: { accessToken: string; }) => {
            var headers = new Headers();
            var bearer = "Bearer " + response.accessToken;
            headers.append("X-Auth", bearer);
            var options = {
                method: "POST",
                headers: headers,
                body: tempData
            };
            var url = API_URL + endpoint;
            return fetch(url, options)
                .then(response => response.json())
                .then(response => { return response; });
        });
    }
};

export async function callMsGraph(accessToken: string) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

function getAuthenticatedClient(accessToken: string) {
    // Initialize Graph client
    const client = Client.init({
        // Use the provided access token to authenticate
        // requests
        authProvider: (done: any) => {
            done(null, accessToken);
        }
    });

    return client;
}

export async function postMsGraph(accessToken: string, url: string, string?: any) {
    const client = getAuthenticatedClient(accessToken);

    const data = await client
        .api(url)
        .post(string);
    return data;
}