import React from 'react';
import { version as webVersion } from "../package.json"
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./Services/authConfig";
import { AuthRoute } from './Services/authRoute';
import { initializeIcons, setIconOptions } from '@fluentui/react'


function App() {
  console.log("Bourne Portal Web - Version: " + webVersion);
  const msalInstance = new PublicClientApplication(msalConfig);
  initializeIcons();
  //Suppress icon warnings.
  setIconOptions({ disableWarnings: true, });

  return (
    <MsalProvider instance={msalInstance}>
      <AuthRoute />
    </MsalProvider>
  );
}

export default App;
