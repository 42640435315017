import { Configuration, PopupRequest } from "@azure/msal-browser";

if (process.env.NODE_ENV === 'production') {
    var msalUri = "https://" + window.location.hostname;
} else {
    // eslint-disable-next-line @typescript-eslint/no-redeclare
    var msalUri = "http://localhost:3000";
}

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "24e20827-6b42-464b-be34-a281f887664a",
        redirectUri: msalUri
    },
    cache: {
        cacheLocation: 'localStorage'
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};