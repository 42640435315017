import React, { lazy, Suspense, useEffect, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { postMsGraph } from "./API";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    NavLink,
    Redirect,
} from "react-router-dom";
import { Icon, Spinner, SpinnerSize } from "@fluentui/react";
import "../style.css"

/* Load Pages */
const StaffHome = lazy(() => import('../Modules/Home/StaffHome'));
const StudentHome = lazy(() => import('../Modules/Home/StudentHome'));
const Error404 = lazy(() => import('../Modules/Core/Error404'));
const DevTimeTableDev = lazy(() => import('../Modules/Dev/TimeTableDev'));

const CreateSlip = lazy(() => import('../Modules/Slips/CreateSlip'));
const ViewSlip = lazy(() => import('../Modules/Slips/ViewSlip'));
const OnCallSlips = lazy(() => import('../Modules/Slips/OnCallSlips'));
const ViewSlips = lazy(() => import('../Modules/Slips/ViewSlips'));
const SlipsStudentSync = lazy(() => import('../Modules/Slips/SlipsStudentSync'));

const AspireStudentHome = lazy(() => import('../Modules/Aspire/AspireStudentHome'));
const AspireStudentCategory = lazy(() => import('../Modules/Aspire/AspireStudentCategory'));
const AspireStaffStudentView = lazy(() => import('../Modules/Aspire/AspireStaffStudentView'));
const AspireStaffStudentList = lazy(() => import('../Modules/Aspire/AspireStaffStudentList'));

const ExamClock = lazy(() => import('../Modules/Exam/ExamClock'));

const renderLoader = () => <div><br /><Spinner size={SpinnerSize.large} /></div>;

export const AuthRoute = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [allowHome, setAllowHome] = useState(false)
    const [allowStaff, setAllowStaff] = useState(false)
    const [allowStudents, setAllowStudents] = useState(false)
    const [allowAspire, setAllowAspire] = useState(false)
    const [allowDev, setAllowDev] = useState(false)
    const [allowPreview, setAllowPreview] = useState(false)

    const [toggled, setToggled] = useState("toggled")

    useEffect(() => {
        const groupReqString = {
            securityEnabledOnly: true
        };

        if (account === null && inProgress === "none") {
            instance.loginRedirect(loginRequest)
        } else if (account && inProgress === "none") {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: account
            }).then(async (response: any) => {
                const groups = await postMsGraph(response.accessToken, "/me/getMemberObjects", groupReqString)
                //console.log("Access Token", response.accessToken)

                const groupHome = ["54c5a100-d6f6-4890-9942-a9a54c0cd52c"];
                const groupStaff = ["e67c6202-fa49-4f55-983b-ba608d833a7e"];
                const groupStudents = ["e3c16df6-cf38-4753-b42f-e6c9d4b089ac"];
                const groupAspire = ["a174c202-93f4-408a-b35f-58071f9fa48c"];
                const groupDev = ["93c43777-b5a7-4117-a89b-dfa5c73e02ae"];
                const groupPreview = ["d04eb640-2e52-4158-9c6a-3e926ca4bb94"];

                if (groupHome.filter(groupId => groups.value.includes(groupId)).length > 0) {
                    setAllowHome(true);
                    console.log("ALLOW Home")
                }

                if (groupStaff.filter(groupId => groups.value.includes(groupId)).length > 0) {
                    setAllowStaff(true);
                    setAllowAspire(true);
                    setAllowHome(true);
                    console.log("ALLOW Staff")
                }

                if (groupStudents.filter(groupId => groups.value.includes(groupId)).length > 0) {
                    setAllowStudents(true);
                    setAllowAspire(true);
                    setAllowHome(true);
                    console.log("ALLOW Students")
                }

                if (groupAspire.filter(groupId => groups.value.includes(groupId)).length > 0) {
                    setAllowAspire(true);
                    console.log("ALLOW Aspire")
                }

                if (groupDev.filter(groupId => groups.value.includes(groupId)).length > 0) {
                    setAllowDev(true);
                    localStorage.setItem('DEV', "true")
                    console.log("ALLOW Dev")
                }

                if (groupPreview.filter(groupId => groups.value.includes(groupId)).length > 0) {
                    setAllowPreview(true);
                    localStorage.setItem('PREVIEW', "true")
                    console.log("ALLOW Preview")
                }

            }).catch(function (error: any) {
                // call acquireTokenPopup in case of acquireTokenSilent failure
                // due to consent or interaction required
                console.log("Error silently acquiring token", error)

                if (error.errorCode === "consent_required"
                    || error.errorCode === "interaction_required"
                    || error.errorCode === "login_required") {
                    instance.loginRedirect({
                        ...loginRequest,
                        account: account
                    }).then(async (response: any) => {
                        console.log("Access Token", response.accessToken)

                        const groups = await postMsGraph(response.accessToken, "/me/getMemberObjects", groupReqString)
                        //console.log("Access Token", response.accessToken)

                        const groupHome = ["54c5a100-d6f6-4890-9942-a9a54c0cd52c"];
                        const groupStaff = ["e67c6202-fa49-4f55-983b-ba608d833a7e"];
                        const groupStudents = ["e3c16df6-cf38-4753-b42f-e6c9d4b089ac"];
                        const groupAspire = ["a174c202-93f4-408a-b35f-58071f9fa48c"];
                        const groupDev = ["93c43777-b5a7-4117-a89b-dfa5c73e02ae"];
                        const groupPreview = ["d04eb640-2e52-4158-9c6a-3e926ca4bb94"];

                        if (groupHome.filter(groupId => groups.value.includes(groupId)).length > 0) {
                            setAllowHome(true);
                            console.log("ALLOW Home")
                        }

                        if (groupStaff.filter(groupId => groups.value.includes(groupId)).length > 0) {
                            setAllowStaff(true);
                            setAllowAspire(true);
                            setAllowHome(true);
                            console.log("ALLOW Staff")
                        }

                        if (groupStudents.filter(groupId => groups.value.includes(groupId)).length > 0) {
                            setAllowStudents(true);
                            setAllowAspire(true);
                            setAllowHome(true);
                            console.log("ALLOW Students")
                        }

                        if (groupAspire.filter(groupId => groups.value.includes(groupId)).length > 0) {
                            setAllowAspire(true);
                            console.log("ALLOW Aspire")
                        }

                        if (groupDev.filter(groupId => groups.value.includes(groupId)).length > 0) {
                            setAllowDev(true);
                            localStorage.setItem('DEV', "true")
                            console.log("ALLOW Dev")
                        }

                        if (groupPreview.filter(groupId => groups.value.includes(groupId)).length > 0) {
                            setAllowPreview(true);
                            localStorage.setItem('PREVIEW', "true")
                            console.log("ALLOW Preview")
                        }

                    }).catch(function (error: any) {
                        console.log(error);
                    });
                }
            });
        }
    }, [account, inProgress, instance]);

    function onToggle() {
        if (toggled === "toggled") {
            setToggled("")
        } else {
            setToggled("toggled")
        }
    }

    return (
        <div className="app">
            <AuthenticatedTemplate>
                <Router>
                    <div className="topbar">
                        <div className="topbar-title">The Bourne Academy</div>
                        <Icon className="topbar-toggler-icon" iconName="CollapseMenu" onClick={() => onToggle()} />
                    </div>
                    <div id="wrapper" className={toggled}>
                        <nav id="sidebar-wrapper" className={toggled}>
                            <ul className="sidebar-nav">
                                {allowHome && (
                                    <li><NavLink activeClassName="sidebar-nav-selected" to="/home" title="Home">
                                        <Icon iconName="HomeSolid" />
                                    </NavLink></li>
                                )}

                                {allowStaff && (
                                    <li><NavLink activeClassName="sidebar-nav-selected" to="/slips" title="Slips">
                                        <Icon iconName="PageCheckedOut" />
                                    </NavLink></li>
                                )}

                                {allowDev && (
                                    <li><NavLink activeClassName="sidebar-nav-selected" to="/BourneDocs" title="Bourne Docs">
                                        <Icon iconName="Documentation" />
                                    </NavLink></li>
                                )}

                                {allowDev && (
                                    <li><NavLink activeClassName="sidebar-nav-selected" to="/TechDocs" title="Tech Docs">
                                        <Icon iconName="DocumentSet" />
                                    </NavLink></li>
                                )}

                                {allowAspire && (
                                    <li><NavLink activeClassName="sidebar-nav-selected" to="/aspire" title="ASPIRE">
                                        <Icon iconName="FavoriteStarFill" />
                                    </NavLink></li>
                                )}

                                {allowPreview && (
                                    <li>
                                        <Icon iconName="Preview" />
                                    </li>
                                )}

                                {allowDev && (
                                    <li>
                                        <Icon iconName="F12DevTools" />
                                    </li>
                                )}

                            </ul>
                        </nav>

                        <div id="page-content-wrapper">

                            <Switch>
                                {allowStaff && (
                                    <Route path="/home">
                                        <Suspense fallback={renderLoader()}>
                                            <StaffHome />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route path="/examclock">
                                        <Suspense fallback={renderLoader()}>
                                            <ExamClock />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route exact path="/">
                                        <Suspense fallback={renderLoader()}>
                                            <StaffHome />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStudents && (
                                    <Route path="/home">
                                        <Suspense fallback={renderLoader()}>
                                            <StudentHome />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStudents && (
                                    <Route exact path="/">
                                        <Suspense fallback={renderLoader()}>
                                            <StudentHome />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowDev && (
                                    <Route exact path="/studenthome">
                                        <Suspense fallback={renderLoader()}>
                                            <StudentHome />
                                        </Suspense>
                                    </Route>
                                )}

                                {allowStaff && (
                                    <Route exact path="/slips/create">
                                        <Suspense fallback={renderLoader()}>
                                            <CreateSlip />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route exact path="/slips/sync">
                                        <Suspense fallback={renderLoader()}>
                                            <SlipsStudentSync />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route exact path="/slips/create/:slipCreateColour">
                                        <Suspense fallback={renderLoader()}>
                                            <CreateSlip />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route exact path="/slips/onCall">
                                        <Suspense fallback={renderLoader()}>
                                            <OnCallSlips />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route exact path="/slips/view">
                                        <Suspense fallback={renderLoader()}>
                                            <ViewSlips />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowDev && (
                                    <Route exact path="/slips/:slipId">
                                        <Suspense fallback={renderLoader()}>
                                            <ViewSlip />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route path="/slips/">
                                        <Suspense fallback={renderLoader()}>
                                            <Redirect to="/slips/create" />
                                        </Suspense>
                                    </Route>
                                )}

                                {allowStudents && (
                                    <Route exact path="/aspire">
                                        <Suspense fallback={renderLoader()}>
                                            <AspireStudentHome />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route exact path="/aspire">
                                        <Suspense fallback={renderLoader()}>
                                            <AspireStaffStudentList />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStudents && (
                                    <Route exact path="/aspire/:aspireCategory">
                                        <Suspense fallback={renderLoader()}>
                                            <AspireStudentCategory />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowStaff && (
                                    <Route exact path="/aspire/staff/:learnerCode/:aspireCategory">
                                        <Suspense fallback={renderLoader()}>
                                            <AspireStaffStudentView />
                                        </Suspense>
                                    </Route>
                                )}
                                {allowDev && (
                                    <Route exact path="/dev/timetable">
                                        <Suspense fallback={renderLoader()}>
                                            <DevTimeTableDev />
                                        </Suspense>
                                    </Route>
                                )}


                                <Route path="/">
                                    <Suspense fallback={renderLoader()}>
                                        <Error404 />
                                    </Suspense>
                                </Route>
                            </Switch>
                        </div>

                    </div>
                </Router>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {renderLoader()}
            </UnauthenticatedTemplate>

        </div>
    );
};